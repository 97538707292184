@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2 */
      url('Open_Sans_300.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhv.woff */
      url('Open_Sans_300.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuht.eot */
      url('Open_Sans_300.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=mem5YaGs126MiZpBA-UN_r8OUuhu&skey=b33cc031a589c168&v=v17#OpenSans */
      url('Open_Sans_300.svg#OpenSans') format('svg'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf */
      url('Open_Sans_300.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2 */
      url('Open_Sans_400.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0d.woff */
      url('Open_Sans_400.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0f.eot */
      url('Open_Sans_400.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=mem8YaGs126MiZpBA-UFVZ0c&skey=62c1cbfccc78b4b2&v=v17#OpenSans */
      url('Open_Sans_400.svg#OpenSans') format('svg'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf */
      url('Open_Sans_400.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2 */
      url('Open_Sans_700.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhv.woff */
      url('Open_Sans_700.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuht.eot */
      url('Open_Sans_700.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=mem5YaGs126MiZpBA-UN7rgOUuhu&skey=cd9e1a36bb25a3c3&v=v17#OpenSans */
      url('Open_Sans_700.svg#OpenSans') format('svg'),
    /* from https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhs.ttf */
      url('Open_Sans_700.ttf') format('truetype');
}
